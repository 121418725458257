const state = {
  user_issues: [],
  user_issue_notification_messages: [],
  get_user_issues_url: "",
  get_user_issue_notification_messages_url: ""
}

const getters = {
  getUserIssuesCount: state => {
    return state.user_issues.length;
  },
  getIssueNotificationMessagesCount: state => {
    return state.user_issue_notification_messages.length;
  },
  getIssueNotificationMessages: state => {
    return state.user_issue_notification_messages
  }
}

// actions
const actions = {
  loadIssues( {commit} ) {
    $.ajax({
      url: state.get_user_issues_url,
      success: function( data ) {
        if (data.status != 'success' ) {
          document.location.reload(true);
        }
        commit('setUserIssues', {user_issues: data.user_issues} )
      }
    })
  },
  loadIssueNotificationMessages( {commit} ) {
    $.ajax({
      url: state.get_user_issue_notification_messages_url,
      success: function( data ) {
        commit('setUserIssueNotificationMessages', {user_issue_notification_messages: data} )
      }
    })
  }
}

// mutations
const mutations = {
  setUserIssues(state, payload) {
    state.user_issues = payload.user_issues;
  },
  setUserIssueNotificationMessages(state, payload) {
    state.user_issue_notification_messages = payload.user_issue_notification_messages;
  },
  setUrls(state, payload) {
    state.get_user_issues_url = payload.get_user_issues_url;
    state.get_user_issue_notification_messages_url = payload.get_user_issue_notification_messages_url;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
