const state = {
  users: []
}

const getters = {
  getUsers: state => {
    return state.users;
  },
  getUserById: state => user_id => {
    var res = state.users.filter( s => s.id == user_id )
    return (res.length == 1) ? res[0].full_name : ''
  }
}

// actions
const actions = {
  loadUsers( {commit} ) {
    $.ajax({
      url: '/users.json',
      success: function( data ) {
        commit('setUsers', {users: data} )
      }
    })

  }
}

// mutations
const mutations = {
  setUsers(state, payload) {
    state.users = payload.users;
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
