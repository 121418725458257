import Vue from 'vue'
import Vuex from 'vuex'

import applies_lists from './modules/applies_lists'
import issues_lists from './modules/issues_lists'
import streets from './modules/streets'
import user_nav from './modules/user_nav'
import users from './modules/users'

Vue.use(Vuex)

//const debug = process.env.NODE_ENV !== 'production'



export default new Vuex.Store({
  modules: {
      applies_lists,
      issues_lists,
      streets,
      user_nav,
      users
  }
//  ,
//  strict: debug,
//  plugins: debug ? [createLogger()] : []
})

