const state = {
  streets: []
}

const getters = {
  getStreets: state => {
    return state.streets;
  },
  getStreetById: state => street_id => {
    var res = state.streets.filter( s => s.id == street_id )
    return (res.length == 1) ? res[0].name : ''
  }
}

// actions
const actions = {
  loadStreets( {commit} ) {
    $.ajax({
      url: '/streets.json',
      success: function( data ) {
        commit('setStreets', {streets: data} )
      }
    })

  }
}

// mutations
const mutations = {
  setStreets(state, payload) {
    state.streets = payload.streets;
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
