import consumer from "./consumer"

consumer.subscriptions.create("WebNotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    $(()=>{
      $('.splash_screen__modal').fadeOut()
      $('.splash_screen__no-connection').hide()
    })
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    $('.splash_screen__modal').addClass('error')
    $('.splash_screen__modal').fadeIn()
    $('.splash_screen__lost').removeClass('d-none')
    setTimeout( ()=> {
      document.location.reload(true);
    }, 5000)
  },

  received(data) {
    $( document ).trigger( data.trigger, data.data );
  }
});



