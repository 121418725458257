//import shop from '../../api/shop'

import Vue from 'vue'

const state = {
  applies: []
}

const getters = {

  listCount: state => list_name => {
    if (typeof(list_name) === 'string') {
      return (typeof(state.applies[list_name]) != 'undefined')? state.applies[list_name].length : 0;
    } else if (typeof(list_name) === 'object') {
      if (typeof(state.applies[list_name.aasm_state]) != 'undefined') {
        if (list_name.type == 'all') {
          return state.applies[list_name.aasm_state].length
        } else if (list_name.type == 'rotten') {
          return state.applies[list_name.aasm_state].filter( a => a.rotten ).length
        } else if (list_name.type == 'priority-high') {
          return state.applies[list_name.aasm_state].filter( a => a.priority === 'high' ).length
        } else if (list_name.type == 'priority-urgent') {
          return state.applies[list_name.aasm_state].filter( a => a.priority === 'urgent' ).length
        } else if (list_name.type == 'join_rotten') {
          return state.applies[list_name.aasm_state].filter( a => a.join_rotten ).length
        }
      } else {
        return 0;
      }
    } else {
      return '-'
    }
  }


}

// actions
const actions = {
  // getAllProducts ({ commit }) {
  //   shop.getProducts(products => {
  //     commit('setProducts', products)
  //   })
  // }
}

// mutations
const mutations = {
  setAppliesList (state, payload) {
    Vue.set(state.applies, payload.payload.list_name, payload.payload.list)
  }
  // setProducts (state, products) {
  //   state.all = products
  // },

  // decrementProductInventory (state, { id }) {
  //   const product = state.all.find(product => product.id === id)
  //   product.inventory--
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}


