require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

window.moment = require('moment');

window.moment.updateLocale('ru', {
    relativeTime : {
      s: 'секунду'
    }
  });

import $ from 'jquery'

import 'bootstrap';
import './../scss/application'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.$ = $;
window.jQuery = $;
window.jquery = $;

window.datepicker = require('air-datepicker');

// Inin Global Vue Obeject to keep shared 'store'
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

import store from '../store'

global.the_vue = Vue
global.the_store = store
global.the_vue.component('multiselect', Multiselect)

store.dispatch('streets/loadStreets')
store.dispatch('users/loadUsers')

$( document ).on( "userListUpdated",
  ( event, data )=> {
    store.dispatch('users/loadUsers')
  });





